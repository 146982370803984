<template>
    <div class="main-content dashboard">
        <h1>Admin Dashboard</h1>
        <div class="dashboard-content full-page-item">

            <div class="dashboard-tiles">
                <router-link to="/contacts" class="dashboard-tile">
                    <div class="dashboard-tile-title">Contacts</div>
                    <div class="dashboard-stat">{{ dashboardStats.contacts }}</div>
                </router-link>
                <router-link to="/donations" class="dashboard-tile">
                    <div class="dashboard-tile-title">Donations</div>
                    <div class="dashboard-stat">{{ dashboardStats.donations }}</div>
                </router-link>
                <router-link to="/registrations" class="dashboard-tile">
                    <div class="dashboard-tile-title">Registrations</div>
                    <div class="dashboard-stat">{{ dashboardStats.registrations }}</div>
                </router-link>
                <router-link to="/users" class="dashboard-tile">
                    <div class="dashboard-tile-title">Users</div>
                    <div class="dashboard-stat">{{ dashboardStats.users }}</div>
                </router-link>
                <router-link to="/content" class="dashboard-tile">
                    <div class="dashboard-tile-title">Content</div>
                    <div class="dashboard-stat">{{ dashboardStats.conent }}</div>
                </router-link>
            </div>

            <div class="dashboard-alerts">
                <h2>Note</h2>
                <p>Programs will be added as data becomes available.</p>
                <p>For example dontations, subscription requests, and contact forms will start to show up once the website goes live and visitors complete those actions.</p>
            </div>

        </div>

    </div>
</template>
<script>
import Dropzone from '@/components/Dropzone.vue'
import { mapActions } from 'vuex'
export default {
    name: 'Dashboard',
    components: {
        Dropzone
    },
    data() {
        return {
            loading: false,
            message: '',
            dashboardStats: {}
        }
    },
    created() {
		this.getDashboardStats()
    },
	methods: {
        ...mapActions([
            'apiCall', // map `this.apiCall()` to `this.$store.dispatch('apiCall')`
        ]),
        getDashboardStats() {
	    	if (this.loading) return
		    this.message = ''
			this.loading = true
    		this.apiCall({ destination: 'get_dashboard_stats' }).then(obj => {
				this.loading = false
    			if (obj.status == 'success') {
                    this.dashboardStats = obj.dashboard_stats
    			} else {
					this.message = obj.message
				}
    		})
        }
    }
}
</script>
<style lang="scss" scoped>
    @import '../styles/_colors.scss';
    .dashboard-content {
    	@media all and (min-width: 980px) {
            display: grid;
            grid-template-columns: 4fr 1fr;
            grid-column-gap: 3em;
    	}
    }
    .dashboard-tiles {
        margin: 0.5em 0 0 0;
    	@media all and (min-width: 980px) {
            height: fit-content;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 2em;
        }
        .dashboard-tile {
            position: relative;
            height: 7em;
            border-top: 6px solid $black;
            border-bottom: 2px solid $black;
            opacity: 0.75;
        	@media all and (min-width: 980px) {
                height: 9em;
            }
            .dashboard-tile-title {
                font-size: 1.25em;
                padding: 0.25em 0 0.25em 0.5em;
                color: $black;
                text-align: left;
                letter-spacing: 1px;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 1em;
            }
            .dashboard-stat {
                font-size: 2em;
                line-height: 1em;
                text-align: left;
                color: $black;
                padding: 0.25em 0 0.25em 0.5em;
            	@media all and (min-width: 980px) {
                    font-size: 4em;
                }
            }
            .dashboard-tile-link {
                position: absolute;
                width: 100%;
                bottom: 0;
                left: 0;
                //width: calc(100% - 2em);
            }
        }
        .dashboard-tile:hover {
            cursor: pointer;
            border-top: 6px solid $logoRed;
            border-bottom: 2px solid $logoRed;
            opacity: 1;
            background-color: $lightgrey;
        }
    }
    .dashboard-alerts {
        font-size: 0.9em;
        h2 {
            border-bottom: 1px dotted $borders;
            margin: 0 0 0.5em 0;
            padding: 0 0 0.25em 0;
        }
    }

</style>
